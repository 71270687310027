import { Link, useLoaderData } from "react-router-dom";
import Page from "../components/layout/page";

export async function ProductLoader({ params }) {
  return { name: `This is ${params.productId}` };
}

export default function Product() {
  const product = useLoaderData();

  return (
    <Page>
      <div id="product">
        <h2>{product.name}</h2>
        <Link to={`/Home`}>Home</Link>
      </div>
    </Page>
  );
}
