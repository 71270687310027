import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';

import Card from './card'

function Block({product}) {
    return (
    <Grid xs={12} sm={6} md={4} lg={3}>
      <Card product={product}></Card>
    </Grid>
    );
}

export default function FullWidthGrid({content}) {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        {content?.map((product)=>{
          return <Block product={product} />;
        })}
      </Grid>
    </Box>
  );
}