import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

// import RouteErrorPage from "./route_error";
/*{
    path: "/",
    element: <HomePage></HomePage>,
    errorElement: <RouteErrorPage />,
    children: [
      {
        path: "contacts/:contactId",
        element: <Contact />,
      },
    ],
  },*/

import HomePage from "../../pages/home";
import Product, { ProductLoader } from "../../pages/product";
import Contact from "../../pages/contact";
import Store from "../../pages/store";
import MaintenancePage from "../../pages/maintenance";

const router = createBrowserRouter([
  {
    path: "/",
    element: <MaintenancePage></MaintenancePage>,
  },
  {
    path: "Home",
    element: <HomePage></HomePage>,
  },
  {
    path: "Store",
    element: <Store></Store>,
  },
  {
    path: "Contact",
    element: <Contact></Contact>,
  },
  {
    path: "product/:productId",
    element: <Product />,
    loader: ProductLoader,
  },
]);

function Router(props) {
  return (
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );
}

export default Router;
