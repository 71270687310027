import YinYangClockImg from '../_assets/YinYangClock.jpg';
import BearPuzzleImg from '../_assets/BearPuzzle.webp';



const AllProducts = [];

const BearPuzzle = {
    name: "BearPuzzle",
    image: BearPuzzleImg
}
AllProducts.push(BearPuzzle);

const YinYangClock = {
    name: "YinYangClock",
    image: YinYangClockImg
}
AllProducts.push(YinYangClock);

export function getProducts () {
    return Promise.resolve(AllProducts);
}



