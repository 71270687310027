import Page from "../components/layout/page";
import React from "react";
import { Link, Outlet } from "react-router-dom";

function HomePage() {
  return (
    <Page>
      <h2>Home page</h2>
      <nav>
        <ul>
          <li>
            <Link to={`/product/1`}>Product 1</Link>
          </li>
          <li>
            <Link to={`/product/2`}>Product 2</Link>
          </li>
        </ul>
      </nav>
      {/*
        <nav>
          <ul>
            <li>
              <Link to={`/contacts/1`}>Contact</Link>
            </li>
          </ul>
        </nav>
  */}
      <div id="detail">
        <Outlet />
      </div>
    </Page>
  );
}

export default HomePage;
