import React from "react";
import Footer from "./footer";
import Menu from "./menu";

function Page(props) {
  return (
    <div>
      <Menu></Menu>
      <div>
        <h1>This is a page</h1>
        {props.children}
      </div>
      <Footer></Footer>
    </div>
  );
}

export default Page;
