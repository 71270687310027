import React from "react";

function Footer(props) {
  return (
    <div>
      <span>footer</span>
    </div>
  );
}

export default Footer;
