import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";

export default function BasicCard({ product }) {
  if (!product) {
    return <></>;
  }
  const { name, image } = product;

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
      <CardMedia
        component="img"
        height="194"
        image={image}
        alt={name}
      />
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {name}
        </Typography>
      </CardContent>
    </Card>
  );
}
