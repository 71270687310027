import React, { useState, useEffect } from "react";
import Page from "../components/layout/page";
import Grid from "../_pageblocks/grid";
import { getProducts } from "../_products/products";

function Store() {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    (async () => {
      const prods = await getProducts();
      setProducts(prods);
    })();
  }, []);

  return (
    <Page>
      <h2>Store</h2>
      <Grid content={products}></Grid>
    </Page>
  );
}

export default Store;
