import React from "react";

function MaintenancePage(props) {
  return (
    <div>
      <div>
        <h1>Welcome To Good Luck</h1>
        This page is currently under maintenance.
      </div>
    </div>
  );
}

export default MaintenancePage;
